import React from "react";

const FeedItem = props => {
  
  const { name, utilization, timer, status, model, machine_index } = props.machSpecs;

  let { timeOn } = props.machSpecs;
  let utilizationall = utilization + "% Utilization"

  const feedItemImg = timer ? (
    <img className="feed-item-img timer" src="./assets/timer.png" alt="Timer" />
  ) : (
    <img className="feed-item-img machlist" src={model} alt="MachList" />
  );

  const timerText = timer ? (
    <div className="feed-item-timer-text">
      <p>{timer.timer}</p>
      <p>{timer.status}</p>
    </div>
  ) : (
    ""
  );

  if (utilization == 0 && status === "Off/Network Disconnected") {
    utilizationall = "Check power & network"
    timeOn = "Click to report"
  } else {
    timeOn = timeOn === "0 Sec" ? "Idle" : `${timeOn} Active`;
    timeOn += "Today"
  }

  return (
    <div
      className="feed-item"
      style={{
        border: status === "Active" ? "2px solid #7ED321" 
              : status === "Off/Network Disconnected" ? "2px solid #d38621" 
              : "2px solid #9B9B9B"
      }}
      onClick={props.toggleMachineSelection(props.machSpecs)}
    >
      <span
        className="feed-indicator-dot"
        style={{
          backgroundColor: status === "Active" ? "#7ED321" 
                          : status === "Off/Network Disconnected" ? "#d38621" 
                          : "#9B9B9B"
        }}
      />
      <div className="feed-item-img-wrapper">
        {feedItemImg}
        {timerText}
      </div>
      <div className="feed-item-text-wrapper">
        <p>{machine_index}{name}</p>
        <div
          style={{
            color:
              utilization >= 66
                ? "#7ED321"
                : utilization <= 39 ? "#BB0000" : "orange"
          }}
        >
          <p>{ utilizationall }</p>
          <p>{timeOn}</p>
        </div>
      </div>
      <p>{status}</p>
    </div>
  );
};

export default FeedItem;
